import React from 'react';
import { Container } from '@mui/material';
import { RootState } from 'typesafe-actions';
import List from '../../../components/list/List';
import { format } from 'date-fns';
import { listValvesAsync } from '../actions';
import * as administrationSelectors from '../selectors';
import { connect } from 'react-redux';
import { SortingParameters } from '../../common/models/listing-parameters';
import { ValveWithCurrentAssignment } from '../models/valves-with-assignment';
import styles from './AdministrationPage.module.scss';

const mapStateToProps = (state: RootState) => ({
  valveList: (params: SortingParameters) =>
    administrationSelectors.adminValveList(state.administration, params),
});

const dispatchProps = {
  getValveList: listValvesAsync.request,
};

interface ComponentProps {}

type Props = ComponentProps &
  ReturnType<typeof mapStateToProps> &
  typeof dispatchProps;

const ValveAdministrationPage: React.FC<Props> = ({
  valveList,
  getValveList,
}) => {
  return (
    <Container className={styles['gaas-administration-page']}>
      <h1>Manometer Administration</h1>
      <p>
        <b>Informationen zur Suche:</b> Kundenummer und Seriennummer müssen
        komplett angegeben werden. Notiz, Name und ID werden auch auf
        Teiltreffer durchsucht.{' '}
      </p>
      <List
        hasPagination={true}
        hasSearchBar={true}
        hasSorting={false}
        editEntityLink={`/admin/edit-valve`}
        columnLabels={[
          { prop: 'id', label: 'Manometer-ID' },
          { prop: 'serialNumber', label: 'Serialnummer' },
          { prop: 'assignment.customerId', label: 'WEF-Nr' },
          { prop: 'assignment?.startTs', label: 'Zugewiesen seit' },
          { prop: 'assignment?.deviceName', label: 'Name' },
          // { prop: 'xxx', label: 'Letztes Paket' },
          // { prop: 'yyy', label: 'Alarmzustand' },
          { prop: 'note', label: 'Notiz' },
        ]}
        columnLabelGrid={'1.75fr 1.0fr 1.0fr 1.75fr 1.75fr 1.75fr auto'}
        rowValueTransform={(obj: ValveWithCurrentAssignment) => [
          obj.id.slice(18, -4),
          obj.serialNumber,
          obj.assignment?.customerId ?? '-',
          // '-'
          obj.assignment?.startTs != null
            ? format(obj.assignment.startTs, 'dd.MM.yy')
            : '-',
          obj.assignment?.deviceName ?? '-',
          obj.note?.slice(0, 96) ?? 'Keine',
        ]}
        searchProps={['id']}
        entityKey={'valveswithassignments'}
        listRequest={sortingParams => getValveList(sortingParams)}
        listSelector={valveList}
      />
    </Container>
  );
};

export default connect(mapStateToProps, dispatchProps)(ValveAdministrationPage);
