import React from 'react';
import { Devider } from './Devider';
import { LabelAndText } from './LabelAndText';
import styles from './Sidebar.module.scss';
import { Skeleton } from './Skeleton';

interface Props {
  title: string;
  loading?: 'generic' | 'details';
}

export const Sidebar: React.FC<Props> = ({ title, loading, children }) => {
  return (
    <div className={styles['gaas-sidebar']}>
      <h1 className={styles['gaas-sidebar--title']}>{title}</h1>

      {loading ? (
        <div>
          <Skeleton height={56} />
          {loading === 'details' && (
            <>
              <Devider />
              <LabelAndText label="Kundennummer" loading />
              <LabelAndText label="Adresse" loading />
            </>
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
};
