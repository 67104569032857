import { useState } from 'react';
import { GroupCard as GroupCardStory } from '../../../stories/GroupCard';
import { GroupMaterial } from '../../../stories/GroupMaterial';
import { ContainerStateView } from '../../inventory/models/inventory';
import EditGroupModal from './EditGroupModal';
import GroupContainerListModal from './GroupContainerListModal';
import { CustomerManagedGroup } from '../models/customer-managed-group';
import {
  ConsumptionHistory,
  ConsumptionHistoryItem,
} from '../models/consumption-history';
import { PermissionQueryResponse } from '../../common/models/permission-query-response';

interface ComponentProps {
  group: CustomerManagedGroup;
  allGroups: CustomerManagedGroup[];
  containers: ContainerStateView[];
  customerId: string;
  history?: ConsumptionHistory;
  historyLoading: boolean;
  readGroupConsumptionPermission: PermissionQueryResponse;
}

const GroupCard: React.FC<ComponentProps> = ({
  group,
  containers,
  history,
  historyLoading,
  allGroups,
  customerId,
  readGroupConsumptionPermission,
}) => {
  const [selectedContainers, setSelectedContainers] = useState<
    ContainerStateView[] | undefined
  >(undefined);
  const [editGroupModalIsOpen, setEditGroupModalIsOpen] =
    useState<boolean>(false);

  const groupedContainers = containers.reduce<{
    [mat: string]: ContainerStateView[];
  }>((acc, container) => {
    const material = container.materialDescription;
    if (!(material in acc)) {
      acc[material] = [];
    }
    acc[material].push(container);

    return acc;
  }, {});

  const groupHistory: ConsumptionHistoryItem[] = (history?.items ?? []).filter(
    item => item.groupId === group.id
  );
  const groupConsumption = groupHistory.reduce(
    (acc, item) => acc + item.openedContainers,
    0
  );
  const groupedGroupHistory = groupHistory.reduce<{
    [mat: string]: ConsumptionHistoryItem;
  }>((acc, historyItem) => {
    const material = historyItem.materialDescription;
    acc[material] = historyItem;

    return acc;
  }, {});

  const allMaterials = Array.from(
    new Set([
      ...Object.keys(groupedGroupHistory),
      ...Object.keys(groupedContainers),
    ])
  );

  const containerEntries = allMaterials.map(material => {
    const materialConsumption =
      groupedGroupHistory[material]?.openedContainers ?? 0;
    const materialContainers = groupedContainers[material] ?? [];
    return (
      <GroupMaterial
        material={material}
        readGroupConsumptionPermission={readGroupConsumptionPermission}
        inventory={materialContainers.length}
        consumption={materialConsumption}
        consumptionLoading={historyLoading}
        setListModalOpen={() => setSelectedContainers(materialContainers)}
        key={`${group.id}${material}`}
      />
    );
  });

  const positionInOrder = [...allGroups]
    .sort((a, b) => a.customOrder - b.customOrder)
    .findIndex(g => g.id === group.id);
  // const positionInOrder = allGroups.findIndex(g => g.id === group.id);

  return (
    <>
      <GroupCardStory
        name={group.name}
        inventory={containers.length}
        consumption={groupConsumption}
        consumptionLoading={historyLoading}
        readGroupConsumptionPermission={readGroupConsumptionPermission}
        materials={containerEntries}
        materialsCount={containerEntries.length}
        setEditModalOpen={() => setEditGroupModalIsOpen(true)}
      />

      <GroupContainerListModal
        onClose={() => setSelectedContainers(undefined)}
        containers={selectedContainers}
        groupName={group.name}
      />

      <EditGroupModal
        onClose={() => setEditGroupModalIsOpen(false)}
        isOpen={editGroupModalIsOpen}
        currentGroup={group}
        allGroups={allGroups}
        customerId={customerId}
        initialOrderPosition={positionInOrder}
        maxOrderPosition={allGroups.length}
      />
    </>
  );
};

export default GroupCard;
