import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf, RootAction, RootState, Services } from 'typesafe-actions';
import {
  clearValveAssignmentCacheSync,
  getValveAssignmentsAsync,
  getValveAsync,
  fetchNextValveOfflineEventsAsync,
  listValvesAsync,
  pairValveToCustomerAsync,
  unpairValveFromCustomerAsync,
  updateValveNoteAsync,
  fetchNextValveSuspiciousValueEventsAsync,
  fetchNextThresholdPassedEventsAsync,
  listGatewaysAsync,
  getGatewayAsync,
  updateGatewayNoteAsync,
  getGatewayAssignmentsAsync,
  unpairGatewayFromCustomerAsync,
  pairGatewayToCustomerAsync,
  clearGatewayAssignmentCacheSync,
  editMaintenanceNoteAsync,
} from './actions';

export const pairValveToCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { administration }) =>
  action$.pipe(
    filter(isActionOf(pairValveToCustomerAsync.request)),
    switchMap(params =>
      administration
        .pairValveToCustomer(
          params.payload.digimanoId,
          params.payload.customerId,
          params.payload.upsert
        )
        .pipe(
          concatMap(() =>
            from([
              pairValveToCustomerAsync.success({}),
              clearValveAssignmentCacheSync({}),
            ])
          ),
          catchError(error => {
            return of(pairValveToCustomerAsync.failure(error));
          })
        )
    )
  );

export const unpairValveFromCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { administration }) =>
  action$.pipe(
    filter(isActionOf(unpairValveFromCustomerAsync.request)),
    switchMap(params =>
      administration
        .unpairValveFromCustomer(params.payload.deviceIdentifier)
        .pipe(
          concatMap(() =>
            from([
              unpairValveFromCustomerAsync.success({}),
              clearValveAssignmentCacheSync({}),
            ])
          ),
          catchError(error => {
            return of(unpairValveFromCustomerAsync.failure(error));
          })
        )
    )
  );

export const pairGatewayToCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { administration }) =>
  action$.pipe(
    filter(isActionOf(pairGatewayToCustomerAsync.request)),
    switchMap(params =>
      administration
        .pairGatewayToCustomer(
          params.payload.gatewayId,
          params.payload.customerId,
          params.payload.upsert
        )
        .pipe(
          concatMap(() =>
            from([
              pairGatewayToCustomerAsync.success({}),
              clearGatewayAssignmentCacheSync({}),
            ])
          ),
          catchError(error => {
            return of(pairGatewayToCustomerAsync.failure(error));
          })
        )
    )
  );

export const unpairGatewayFromCustomer: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { administration }) =>
  action$.pipe(
    filter(isActionOf(unpairGatewayFromCustomerAsync.request)),
    switchMap(params =>
      administration.unpairGatewayFromCustomer(params.payload.gatewayId).pipe(
        concatMap(() =>
          from([
            unpairGatewayFromCustomerAsync.success({}),
            clearGatewayAssignmentCacheSync({}),
          ])
        ),
        catchError(error => {
          return of(unpairGatewayFromCustomerAsync.failure(error));
        })
      )
    )
  );

export const listGateways: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { valve }
) =>
  action$.pipe(
    filter(isActionOf(listGatewaysAsync.request)),
    switchMap(({ payload: params }) =>
      valve.listGatewaysForAdmins(params).pipe(
        map(result => listGatewaysAsync.success({ result, params: params })),
        catchError(error => {
          return of(listGatewaysAsync.failure({ error, params: params }));
        })
      )
    )
  );

export const listValves: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { valve }
) =>
  action$.pipe(
    filter(isActionOf(listValvesAsync.request)),
    switchMap(({ payload: params }) =>
      valve.listValvesForAdmins(params).pipe(
        map(result => listValvesAsync.success({ result, params: params })),
        catchError(error => {
          return of(listValvesAsync.failure({ error, params: params }));
        })
      )
    )
  );

export const getValve: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { valve }
) =>
  action$.pipe(
    filter(isActionOf(getValveAsync.request)),
    switchMap(({ payload: valveId }) =>
      valve.getValveForAdmins(valveId).pipe(
        map(result => getValveAsync.success(result)),
        catchError(error => {
          return of(getValveAsync.failure({ id: valveId, error }));
        })
      )
    )
  );

export const getGateway: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _,
  { valve }
) =>
  action$.pipe(
    filter(isActionOf(getGatewayAsync.request)),
    switchMap(({ payload: gatewayId }) =>
      valve.getGatewayForAdmins(gatewayId).pipe(
        map(result => getGatewayAsync.success(result)),
        catchError(error => {
          return of(getGatewayAsync.failure({ id: gatewayId, error }));
        })
      )
    )
  );

export const getValveAssignments: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(getValveAssignmentsAsync.request)),
    switchMap(({ payload: { id: valveId, params } }) =>
      valve.getValveAssignments(valveId, params).pipe(
        map(result =>
          getValveAssignmentsAsync.success({
            id: valveId,
            assignments: result,
            params,
          })
        ),
        catchError(error => {
          return of(
            getValveAssignmentsAsync.failure({ id: valveId, error, params })
          );
        })
      )
    )
  );

export const getGatewayAssignments: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(getGatewayAssignmentsAsync.request)),
    switchMap(({ payload: { id: gatewayId, params } }) =>
      valve.getGatewayAssignments(gatewayId, params).pipe(
        map(result =>
          getGatewayAssignmentsAsync.success({
            id: gatewayId,
            assignments: result,
            params,
          })
        ),
        catchError(error => {
          return of(
            getGatewayAssignmentsAsync.failure({ id: gatewayId, error, params })
          );
        })
      )
    )
  );

export const updateValveNote: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(updateValveNoteAsync.request)),
    switchMap(({ payload: { valveId, note } }) =>
      valve.updateValveNote(valveId, note).pipe(
        // map(result => [updateValveNoteAsync.success({ valveId, note })]),
        concatMap(() =>
          from([
            updateValveNoteAsync.success({ valveId, note }),
            getValveAsync.request(valveId),
          ])
        ),
        catchError(error => {
          return of(updateValveNoteAsync.failure({ id: valveId, error }));
        })
      )
    )
  );

export const updateGatewayNote: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(updateGatewayNoteAsync.request)),
    switchMap(({ payload: { gatewayId, note } }) =>
      valve.updateGatewayNote(gatewayId, note).pipe(
        map(result => updateGatewayNoteAsync.success({ gatewayId })),
        catchError(error => {
          return of(updateGatewayNoteAsync.failure({ id: gatewayId, error }));
        })
      )
    )
  );

export const getValveOfflineEvents: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(fetchNextValveOfflineEventsAsync.request)),
    switchMap(({ payload: { valveId, lastKey } }) =>
      valve.getValveOfflineEvents(valveId, lastKey).pipe(
        map(result =>
          fetchNextValveOfflineEventsAsync.success({
            valveId,
            response: result,
          })
        ),
        catchError(error => {
          return of(
            fetchNextValveOfflineEventsAsync.failure({ id: valveId, error })
          );
        })
      )
    )
  );

export const getValveSuspiciousValueEvents: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(fetchNextValveSuspiciousValueEventsAsync.request)),
    switchMap(({ payload: { valveId, lastKey } }) =>
      valve.getValveSuspiciousValueEvents(valveId, lastKey).pipe(
        map(result =>
          fetchNextValveSuspiciousValueEventsAsync.success({
            valveId,
            response: result,
          })
        ),
        catchError(error => {
          return of(
            fetchNextValveSuspiciousValueEventsAsync.failure({
              id: valveId,
              error,
            })
          );
        })
      )
    )
  );

export const getThresholdPassedEvents: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { valve }) =>
  action$.pipe(
    filter(isActionOf(fetchNextThresholdPassedEventsAsync.request)),
    switchMap(({ payload: { valveId, lastKey, lastId } }) =>
      valve.getThresholdPassedEvents(valveId, lastKey, lastId).pipe(
        map(result =>
          fetchNextThresholdPassedEventsAsync.success({
            valveId,
            response: result,
          })
        ),
        catchError(error => {
          return of(
            fetchNextThresholdPassedEventsAsync.failure({ id: valveId, error })
          );
        })
      )
    )
  );

export const editMaintenanceNote: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, _, { administration }) =>
  action$.pipe(
    filter(isActionOf(editMaintenanceNoteAsync.request)),
    switchMap(({ payload }) =>
      administration.editMaintenanceNote(payload).pipe(
        map(result => editMaintenanceNoteAsync.success()),
        catchError(error => {
          return of(editMaintenanceNoteAsync.failure(error));
        })
      )
    )
  );
