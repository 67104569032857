import React, { FC, useEffect, useState } from 'react';
import { Modal } from '../../../stories/Modal';
import { CustomerManagedGroup } from '../../groups/models/customer-managed-group';
import { Input } from '../../../stories/Input';
import { PermissionQueryResponse } from '../../common/models/permission-query-response';
import { Select } from '../../../stories/Select';

const NO_GROUP_SELECTED_KEY = '__NO_GROUP_SELECTED__';

interface ComponentProps {
  open: boolean;
  onClose: () => void;
  onNameChange: (name: string | null) => void;
  meterName: string | null;
  allAvailableGroups: CustomerManagedGroup[];
  currentGroupId?: string;
  currentSortingPosition: number;
  onGroupChange: (groupId: string | undefined) => void;
  maxSortingPosition: number;
  onSortingPositionChange: (sortingPosition: number) => void;
  permissionChangeName: PermissionQueryResponse;
  permissionChangeGroup: PermissionQueryResponse;
}

const DigiDrumiEditModal: FC<ComponentProps> = ({
  open,
  onClose,
  meterName,
  onNameChange,
  allAvailableGroups,
  currentGroupId,
  onGroupChange,
  currentSortingPosition,
  maxSortingPosition,
  onSortingPositionChange,
  permissionChangeName,
  permissionChangeGroup,
}) => {
  const [name, setName] = useState<string | null>(meterName);
  const [selectedGroupId, setSelectedGroupId] = useState<string>(
    NO_GROUP_SELECTED_KEY
  );
  const [sortingPosition, setSortingPosition] = useState<number>(
    currentSortingPosition
  );

  useEffect(() => {
    setName(meterName);
  }, [meterName]);

  useEffect(() => {
    setSelectedGroupId(currentGroupId ?? NO_GROUP_SELECTED_KEY);
  }, [currentGroupId]);

  const handleSave = () => {
    if (name !== meterName) {
      onNameChange(name);
    }

    const realNewGroupId =
      selectedGroupId === NO_GROUP_SELECTED_KEY ? undefined : selectedGroupId;

    if (sortingPosition !== currentSortingPosition) {
      onSortingPositionChange(sortingPosition);
    }
    if (realNewGroupId !== currentGroupId) {
      onGroupChange(realNewGroupId);
    }
    onClose();
  };

  const onSortingPositionSelected = (sortingPositionString: string) => {
    setSortingPosition(Number.parseInt(sortingPositionString ?? '0', 10));
    setSelectedGroupId(currentGroupId ?? NO_GROUP_SELECTED_KEY);
  };

  const onGroupSelected = (newGroupId: string | undefined) => {
    setSelectedGroupId(newGroupId ?? NO_GROUP_SELECTED_KEY);
    setSortingPosition(currentSortingPosition);
  };

  return (
    <Modal
      title={'Meter bearbeiten'}
      open={open}
      onClose={onClose}
      onSave={{ label: 'Speichern', action: handleSave }}
    >
      <Input
        label={'Meter Name'}
        placeholder={'Namen eingeben...'}
        onChange={e => setName(e.target.value === '' ? null : e.target.value)}
        type={'text'}
        name={'change-meter-name'}
        value={name ?? ''}
        permission={permissionChangeName}
      />

      <Select
        label={'Gruppe'}
        id={'valve-group-select'}
        value={selectedGroupId}
        onChange={onGroupSelected}
        disabled={
          // we enable the select as soon as we have one group
          // as "no group" is also always an option
          allAvailableGroups.length < 1 ||
          sortingPosition !== currentSortingPosition
        }
        dropoutStyleOverrides={{ zIndex: 1 }}
        options={[
          ...[...allAvailableGroups]
            .sort((groupA, groupB) => groupA.name.localeCompare(groupB.name))
            .map(group => ({
              label: group.name,
              value: group.id,
            })),
          {
            label: 'Keine Verbrauchsgruppe',
            value: NO_GROUP_SELECTED_KEY,
          },
        ]}
        permission={permissionChangeGroup}
      />

      <Select
        label={'Sortierungsposition'}
        id={'valve-position-select'}
        value={`${sortingPosition}`}
        disabled={
          maxSortingPosition <= 1 ||
          selectedGroupId !== (currentGroupId ?? NO_GROUP_SELECTED_KEY)
        }
        onChange={onSortingPositionSelected}
        options={new Array(maxSortingPosition).fill(0).map((_, index) => ({
          label: `${index + 1}`,
          value: `${index}`,
        }))}
        permission={permissionChangeGroup}
      />
    </Modal>
  );
};

export default DigiDrumiEditModal;
