import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { ManometerLoading } from '../../../stories/ManometerLoading';
import { TextButton } from '../../../stories/TextButton';
import { Replay } from '../../../stories/icons';
import CustomerSelection from '../../common/components/CustomerSelection';
import * as commonSelectors from '../../common/selectors';
import * as digidrumiActions from '../actions';
import DigiDrumiCard from '../components/DigiDrumiCard';
import * as digidrumiSelectors from '../selectors';
import styles from './DigiDrumiPage.module.scss';
import { ManometerCardDummy } from '../../../stories/ManometerCardDummy';
import DigiDrumiGroupAccordion from '../components/DigiDrumiGroupAccordion';
import { ApiValveState } from '../../../models/openapi/openapiTypes';
import * as groupActions from '../../groups/actions';
import * as groupSelectors from '../../groups/selectors';
import { CustomerManagedGroup } from '../../groups/models/customer-managed-group';

const NO_GROUP_MARKER = 'NONE';

const mapStateToProps = (state: RootState) => ({
  valves: digidrumiSelectors.valves(state.digidrumi),
  selectedCustomer: commonSelectors.selectedCustomer(state.common),
  thresholds: digidrumiSelectors.thresholds(state.digidrumi),
  customerGroups: (customerId: string) =>
    groupSelectors.customerGroups(state.groups, customerId),
});

const dispatchProps = {
  getValves: digidrumiActions.getValvesAsync.request,
  getThresholds: digidrumiActions.getThresholdAsync.request,
  getCustomerGroups: groupActions.getCustomerGroupsAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const DigiDrumi: React.FC<Props> = ({
  valves,
  thresholds,
  selectedCustomer,
  getValves,
  getThresholds,
  getCustomerGroups,
  customerGroups,
}) => {
  const customerId = selectedCustomer?.customerId;
  useEffect(() => {
    if (valves.error || valves.loading) {
      return;
    }
    if (!valves.data && customerId) {
      getValves(customerId);
    }
  }, [valves.error, valves.loading, valves.data, customerId, getValves]);

  useEffect(() => {
    if (thresholds.error || thresholds.loading) {
      return;
    }
    if (!thresholds.data && customerId) {
      getThresholds(customerId);
    }
  }, [
    thresholds.error,
    thresholds.loading,
    thresholds.data,
    customerId,
    getThresholds,
  ]);

  const groupsForThisCustomer = customerGroups(customerId ?? '');
  useEffect(() => {
    if (groupsForThisCustomer.error || groupsForThisCustomer.loading) {
      return;
    }
    if (!groupsForThisCustomer.data && customerId) {
      getCustomerGroups({ customerId });
    }
  }, [
    customerId,
    groupsForThisCustomer.data,
    groupsForThisCustomer.error,
    groupsForThisCustomer.loading,
    getCustomerGroups,
  ]);

  const loadValves = () => {
    if (selectedCustomer && !valves.loading) {
      getValves(selectedCustomer.customerId);
    }
  };

  const valveContent = () => {
    if (valves.error) {
      return (
        <div className={styles['gaas-manometer-page--empty']}>
          <p>Laden der Ventilinformationen fehlgeschlagen.</p>
          <TextButton
            label="Erneut versuchen"
            LeadingIcon={Replay}
            onClick={() => loadValves()}
          />
        </div>
      );
    }

    if (valves.loading || groupsForThisCustomer.loading) {
      return (
        <div className={styles['gaas-manometer-page--empty']}>
          <ManometerLoading />
        </div>
      );
    }

    const valvesData = valves.data ?? [];
    const thresholdData = thresholds.data ?? [];
    if (!valvesData.length) {
      return <ManometerCardDummy />;
    }

    valvesData.sort((a, b) => (a.customOrder ?? 0) - (b.customOrder ?? 0));
    const valvesGroupedByGroup = valvesData.reduce<
      Record<string, ApiValveState[]>
    >((acc, val) => {
      const groupIdOfValve = val.groupId ?? NO_GROUP_MARKER;
      if (acc[groupIdOfValve] === undefined) {
        acc[groupIdOfValve] = [];
      }

      acc[groupIdOfValve].push(val);
      return acc;
    }, {});

    // if there is only one group or no group at all, render all valves in one list
    if (Object.keys(valvesGroupedByGroup).length <= 1) {
      return (
        <div className={styles['gaas-manometer-page']} role={'list'}>
          {valvesData.map(valve => (
            <DigiDrumiCard
              valve={valve}
              key={valve.deviceIdentifier}
              thresholds={
                thresholdData?.filter(
                  it => it.deviceId === valve.deviceIdentifier
                ) ?? []
              }
              groups={groupsForThisCustomer.data ?? []}
              maxSortingPosition={valvesData.length}
              sortingPosition={valvesData.findIndex(
                it => it.deviceIdentifier === valve.deviceIdentifier
              )}
            />
          ))}
        </div>
      );
    }

    // sort groups by group name and valves of a group by group name
    // valves without a group are sorted to the end
    const valvesWithGroupAndName = Object.entries<ApiValveState[]>(
      valvesGroupedByGroup
    )
      .map(
        ([groupId, valves]): [
          CustomerManagedGroup | undefined,
          ApiValveState[]
        ] => [
          groupId === NO_GROUP_MARKER
            ? undefined
            : groupsForThisCustomer.data?.find(it => it.id === groupId),
          // valves.sort((a,b) => (b.customOrder ?? 0) - (a.customOrder ?? 0)),
          valves.sort((a, b) => (a.customOrder ?? 0) - (b.customOrder ?? 0)),
        ]
      )
      .sort((a, b) => {
        if (a[0] === undefined) return 1;
        if (b[0] === undefined) return -1;
        return a[0].customOrder - b[0].customOrder;
      });

    return (
      <div>
        {valvesWithGroupAndName.map(([groupForValves, valvesOfAGroup]) => (
          <DigiDrumiGroupAccordion
            groupId={valvesOfAGroup[0].groupId ?? NO_GROUP_MARKER}
            groupName={groupForValves ? groupForValves.name : 'Ohne Gruppe'}
            customerId={customerId ?? NO_GROUP_MARKER}
            key={valvesOfAGroup[0].groupId ?? NO_GROUP_MARKER}
          >
            <div className={styles['gaas-manometer-page']} role={'list'}>
              {valvesOfAGroup.map(valve => (
                <DigiDrumiCard
                  valve={valve}
                  key={valve.deviceIdentifier}
                  thresholds={
                    thresholdData?.filter(
                      it => it.deviceId === valve.deviceIdentifier
                    ) ?? []
                  }
                  groups={groupsForThisCustomer.data ?? []}
                  maxSortingPosition={valvesOfAGroup.length}
                  sortingPosition={valvesOfAGroup.findIndex(
                    it => it.deviceIdentifier === valve.deviceIdentifier
                  )}
                />
              ))}
            </div>
          </DigiDrumiGroupAccordion>
        ))}
      </div>
    );
  };

  return (
    <CustomerSelection title="Digitale Manometer" showGatewayIndicator>
      {valveContent()}
    </CustomerSelection>
  );
};

export default connect(mapStateToProps, dispatchProps)(DigiDrumi);
