import { Route, Switch } from 'react-router-dom';
import React from 'react';
import SalesforceAuthenticator from '../components/SalesforceAuthenticator';
import PublicBottlePage from '../features/public/screens/PublicBottlePage';
import { FrontendEnvironment, getEnvironment } from '../util/amplifyConfig';
import LocalAuthenticator from '../components/LocalAuthenticator';
// import ShopWorkInProgressPage from '../features/shop/screens/ShopWorkInProgressPage';
//
// const shouldDisplayShop = process.env.REACT_APP_STAGE !== 'production';

const UnauthenticatedRoutes: React.FC<{}> = () => {
  const authenticator =
    getEnvironment() === FrontendEnvironment.LOCAL
      ? LocalAuthenticator
      : SalesforceAuthenticator;

  return (
    <Switch>
      <Route
        exact
        path="/public/inspect-bottle/:barcode"
        component={PublicBottlePage}
      />
      {/*{!shouldDisplayShop && (*/}
      {/*  <>*/}
      {/*    <Route*/}
      {/*      exact*/}
      {/*      path="/public/shop/"*/}
      {/*      component={() => <PublicShopPage standalone={true} />}*/}
      {/*    />*/}
      {/*    <Route*/}
      {/*      exact*/}
      {/*      path="/public/shop/free"*/}
      {/*      component={() => <PublicFreeSubscriptionPage standalone={true} />}*/}
      {/*    />*/}
      {/*    <Route*/}
      {/*      exact*/}
      {/*      path="/public/shop/:plan"*/}
      {/*      component={() => <PublicShopPage standalone={true} />}*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}

      {/*{!shouldDisplayShop && (*/}
      {/*  <Route exact path="/shop/*" component={ShopWorkInProgressPage} />*/}
      {/*)}*/}

      <Route path="*" component={authenticator} />
    </Switch>
  );
};

export default UnauthenticatedRoutes;
