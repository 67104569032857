import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './Select.module.scss';
import classNames from 'classnames';
import { LabelAndText } from './LabelAndText';
import { Check, ChevronDownSmall, ChevronUpSmall, Info } from './icons';
import { TextButton } from './TextButton';
import { InfoBox } from './InfoBox';
import useWindowSize from '../hooks/useWindowSize';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';
import { PlansChip } from './PlansChip';
import { PlansModal } from './PlansModal';

interface Option {
  label: string;
  value: any;
  additionalContent?: string | ReactNode;
  disabled?: boolean;
}

interface Props {
  label: string;
  id: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  size?: 's' | 'm' | 'l';
  unit?: string;
  info?: string;
  search?: 'all' | 'label';
  undefinedOptionValue?: string;
  permission?: PermissionQueryResponse;
  dropoutStyleOverrides?: CSSProperties;
}

export const Select: React.FC<Props> = ({
  label,
  id,
  options,
  value,
  onChange,
  disabled,
  required,
  search,
  error,
  errorMessage,
  size = 'm',
  unit,
  info,
  undefinedOptionValue,
  permission,
  dropoutStyleOverrides,
}) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [dropdownOverflows, setDropdownOverflow] = useState(false);
  const [plansModalOpen, setPlansModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const inputRef = useRef<HTMLDivElement | null>(null);
  const inputNodeRef = useRef<HTMLInputElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  let { height } = useWindowSize();

  useEffect(() => {
    if (dropdownRef.current) {
      if (options.length <= 5) return;

      if (optionsVisible) {
        //el position from window top - generic padding
        let dropdownHeight =
          height - dropdownRef.current.getBoundingClientRect().top - 24;

        if (dropdownRef.current.clientHeight > dropdownHeight) {
          setDropdownOverflow(true);
          dropdownRef.current.style.height = dropdownHeight + 'px';
        }
      }
    }
  }, [height, optionsVisible, options.length]);

  const onOutsideClick = useCallback(
    (e: PointerEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        setOptionsVisible(false);
      }
    },
    [setOptionsVisible]
  );

  useEffect(() => {
    window.addEventListener('pointerdown', e => onOutsideClick(e));

    return () =>
      window.removeEventListener('pointerdown', e => onOutsideClick(e));
  }, [onOutsideClick]);

  let displayValue;
  if (undefinedOptionValue !== undefined && undefinedOptionValue === value) {
    displayValue = '';
  } else {
    displayValue = options.find(option => value === option.value)?.label;
  }

  const missingPermissions =
    permission !== undefined && permission?.[0] !== 'GRANTED';
  const disabledThroughPermissions = missingPermissions || (disabled ?? false);

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options;
    }

    return options.filter(option => {
      const { additionalContent } = option;

      return (
        option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
        (search === 'all' &&
          typeof additionalContent === 'string' &&
          additionalContent.toLowerCase().includes(searchValue.toLowerCase()))
      );
    });
  }, [options, searchValue, search]);

  const onFocusLoss = () => {
    setOptionsVisible(false);
    setSearchValue('');
    setIsSearching(false);
  };

  return (
    <div
      className={classNames(
        styles['gaas-select'],
        size && styles[`gaas-select--${size}`],
        {
          [styles['gaas-select--disabled']]: disabledThroughPermissions,
          [styles['gaas-select--error']]: error,
        }
      )}
    >
      <div className={styles['gaas-select--label']}>
        <LabelAndText label={label} labelFor={id} />
        {info && (
          <TextButton
            LeadingIcon={Info}
            onClick={() => setInfoVisible(!infoVisible)}
            inline
          />
        )}
      </div>
      <div
        className={classNames(
          styles['gaas-select--input'],
          !search && styles['gaas-select--input--select']
        )}
        ref={inputRef}
      >
        <input
          id={id}
          ref={inputNodeRef}
          placeholder={`Auswählen${search ? ' oder suchen' : ''}`}
          disabled={disabledThroughPermissions}
          required={required}
          value={isSearching ? searchValue : displayValue}
          onInput={e => setSearchValue(e.currentTarget.value)}
          onFocus={() => {
            setOptionsVisible(true);
            if (search) {
              setSearchValue('');
              setIsSearching(true);
            }
          }}
          onBlur={onFocusLoss}
          readOnly={!search}
        />
        {optionsVisible && (
          <div
            className={classNames(styles['gaas-select--input--options'], {
              [styles['gaas-select--input--options--withScroll']]:
                dropdownOverflows,
            })}
            ref={dropdownRef}
            style={dropoutStyleOverrides}
          >
            {filteredOptions.map(option => (
              <div
                className={classNames(
                  styles['gaas-select--input--options--option'],
                  {
                    [styles['gaas-select--input--options--option--selected']]:
                      option.value === value,
                    [styles['gaas-select--input--options--option--disabled']]:
                      option.disabled,
                    [styles[
                      'gaas-select--input--options--option--withContent'
                    ]]: option.additionalContent,
                  }
                )}
                onMouseDown={e => {
                  // I (Sebastian) am using onMouseDown instead of onClick
                  // because the click event fires after the parent element
                  // looses focus and the dropdown closes. MouseDown
                  // fires before the blur event and can be suppressed
                  if (option.disabled) return;
                  e.preventDefault();

                  onChange(option.value);

                  onFocusLoss();
                  // because we are using onMouseDown we need to
                  // blur the input manually if an item is selected
                  inputNodeRef.current?.blur();
                }}
                key={option.value}
                aria-disabled={option.disabled}
              >
                <span
                  className={
                    styles['gaas-select--input--options--option--title']
                  }
                >
                  {option.label}
                </span>
                {option.value === value && (
                  <Check
                    className={
                      styles['gaas-select--input--options--option--icon']
                    }
                  />
                )}
                {option.additionalContent &&
                typeof option.additionalContent === 'string' ? (
                  <span
                    className={
                      styles['gaas-select--input--options--option--content']
                    }
                  >
                    {option.additionalContent}
                  </span>
                ) : (
                  option.additionalContent
                )}
              </div>
            ))}
          </div>
        )}
        {infoVisible && (
          <InfoBox>
            <span>{info}</span>
          </InfoBox>
        )}
        {!optionsVisible ? (
          <ChevronDownSmall className={styles['gaas-select--input--icon']} />
        ) : (
          <ChevronUpSmall className={styles['gaas-select--input--icon']} />
        )}
        {unit && (
          <span className={styles['gaas-select--input--unit']}>{unit}</span>
        )}
        {error && (
          <p className={styles['gaas-select--input--hint']}>{errorMessage}</p>
        )}
        {missingPermissions && permission !== undefined && (
          <>
            <PlansChip
              permission={permission}
              onClick={() => setPlansModalOpen(true)}
              top={'50%'}
              right={'50%'}
            />
            <PlansModal
              open={plansModalOpen}
              onClose={() => setPlansModalOpen(false)}
              permission={permission}
            />
          </>
        )}
      </div>
    </div>
  );
};
