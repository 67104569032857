import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { Modal } from '../../../stories/Modal';
import { OutlinedInput } from '@mui/material';
import { Button } from '../../../stories/Button';
import { Devider } from '../../../stories/Devider';
import React, { useEffect, useState } from 'react';
import * as groupSelectors from '../selectors';
import * as groupActions from '../actions';
import { CustomerManagedGroup } from '../models/customer-managed-group';
import { Select } from '../../../stories/Select';

const NO_GROUP_SELECTED_KEY = '__NO_GROUP_SELECTED__';

interface ComponentProps {
  isOpen: boolean;
  onClose: () => void;
  allGroups: CustomerManagedGroup[];
  currentGroup: CustomerManagedGroup;
  customerId: string;
  initialOrderPosition: number;
  maxOrderPosition: number;
}

const mapStateToProps = (state: RootState) => ({
  groupDeleting: (groupId: string) =>
    groupSelectors.groupIsDeleting(state.groups, groupId),
});
const dispatchProps = {
  deleteGroup: groupActions.deleteGroupAsync.request,
  changeGroupName: groupActions.changeGroupNameAsync.request,
  changeGroupOrder: groupActions.changeGroupOrderAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const EditGroupModal: React.FC<Props> = ({
  isOpen,
  onClose,
  allGroups,
  currentGroup,
  groupDeleting,
  deleteGroup,
  customerId,
  initialOrderPosition,
  maxOrderPosition,
  changeGroupName,
  changeGroupOrder,
}) => {
  const [selectedGroupId, setSelectedGroupId] = useState<string>(
    NO_GROUP_SELECTED_KEY
  );
  const [groupName, setGroupName] = useState<string>(currentGroup.name);
  const [groupPosition, setGroupPosition] =
    useState<number>(initialOrderPosition);
  const [groupDeleteRequested, setGroupDeleteRequested] =
    useState<boolean>(false);
  const { data: groupDeleted } = groupDeleting(currentGroup.id);

  useEffect(() => {
    setGroupDeleteRequested(false);
  }, [currentGroup.id]);

  useEffect(() => {
    if (groupDeleteRequested && groupDeleted) {
      onClose();
    }
  });

  const onSave = () => {
    if (currentGroup.name !== groupName) {
      changeGroupName({
        groupId: currentGroup.id,
        newName: groupName,
        oldName: currentGroup.name,
      });
    }

    if (initialOrderPosition !== groupPosition) {
      changeGroupOrder({
        groupId: currentGroup.id,
        newPosition: groupPosition,
        oldPosition: initialOrderPosition,
      });
    }

    onClose();
  };

  const deleteGroupLabel = `Gruppe löschen${
    selectedGroupId === NO_GROUP_SELECTED_KEY
      ? ''
      : ' und alle Zuweisungen übertragen'
  }`;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="Verbrauchsgruppe bearbeiten"
      maxWidth={500}
    >
      <OutlinedInput
        placeholder={'Name der Gruppe'}
        value={groupName}
        onChange={e => setGroupName(e.target.value)}
        sx={{ borderRadius: 0, fontFamily: 'WestfalenNewsSans' }}
        error={groupName.length < 1}
      />

      <Select
        label={'Sortierungsposition'}
        id={'group-order-select'}
        value={`${groupPosition}`}
        disabled={maxOrderPosition <= 1}
        onChange={key => setGroupPosition(Number.parseInt(key ?? '0', 10))}
        options={new Array(maxOrderPosition).fill(0).map((_, index) => ({
          label: `${index + 1}`,
          value: `${index}`,
        }))}
        // permission={permissionChangeGroup}
      />

      <Button
        label={'Änderungen speichern'}
        fullWidth
        onClick={onSave}
        disabled={groupName.length < 1}
      />

      <Devider noMargin />
      <div>
        <h3 style={{ margin: 0 }}>Verbrauchsgruppe löschen</h3>
        <p>
          Wenn Sie diese Gruppen löschen, werden die Zuweisungen aller ihr
          zugewiesenen Flaschen und GaaS-Meter ebenso entfernt. Sie können diese
          Zuweisungen auch auf eine andere Gruppe übertragen, indem Sie das
          untenstehende Auswahlfeld nutzen.
        </p>
        <Select
          label={'Zielgruppe'}
          id={'group-delete-target-select'}
          value={`${selectedGroupId}`}
          dropoutStyleOverrides={{ zIndex: 1 }}
          onChange={key => setSelectedGroupId(key)}
          options={[
            { value: NO_GROUP_SELECTED_KEY, label: 'Keine Verbrauchsgruppe' },
            ...allGroups
              .filter(group => group.id !== currentGroup.id)
              .map(group => ({
                label: group.name,
                value: group.id,
              })),
          ]}
          // permission={permissionChangeGroup}
        />
      </div>

      <Button
        label={deleteGroupLabel}
        fullWidth
        loading={groupDeleteRequested}
        onClick={() => {
          setGroupDeleteRequested(true);
          deleteGroup({
            groupId: currentGroup.id,
            customerId,
            request: {
              newGroupId:
                selectedGroupId === NO_GROUP_SELECTED_KEY
                  ? undefined
                  : selectedGroupId,
            },
          });
        }}
      />
    </Modal>
  );
};

export default connect(mapStateToProps, dispatchProps)(EditGroupModal);
